















import { Vue } from 'vue-property-decorator'
import Component from 'vue-class-component'

import ProgressList from '@/components/shared/ProgressList.vue'
import UserDetailsForm from '@/components/userDetails/UserDetailsForm.vue'

@Component({
  components: {
    ProgressList,
    UserDetailsForm
  }
})
export default class UserDetailsView extends Vue {
}
